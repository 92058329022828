import React, {useState} from 'react'; 
import '../../App.css';
import './EventsList.css';
import { Button } from '../ButtonModals';
import Modal from 'react-bootstrap/Modal';
import 'bootstrap/dist/css/bootstrap.min.css';

function EventsList() {
  const [modal1show, setmodal1show] = useState(false);
  const [modal2show, setmodal2show] = useState(false);
  const [modal3show, setmodal3show] = useState(false);
  const [modal4show, setmodal4show] = useState(false);
  const [modal5show, setmodal5show] = useState(false);
  const [modal6show, setmodal6show] = useState(false);
return (
<>
<section class="events-section">
  <div class="events-container">
    <h2 class="section-title">OUR EVENTS</h2>


    <article class="product geoclip spacing paddown-hey">
      <img src="/images/icon-web/icon_geoclip.png" alt="icon-event" class="pimage1" />
      <h3 class="product__title" id='title1'>Geoclip</h3>
      <div className= 'btn-events'>
        <Button  onClick={() => setmodal1show(true)} buttonStyle='btn--outline'>EVENT DETAILS</Button>
      </div>



    </article>

    <article class="product gis-event spacing paddown-hey1">
      <img src="/images/icon-web/icon_gis.png" alt="icon-event" 
      class="pimage2" />
      <div>
        
      </div>
      <h3 class="product__title">Geographic Information System (GIS)</h3>


      <div className= 'btn-events'>
        <Button onClick={() => setmodal2show(true)} buttonStyle='btn--outline'>EVENT DETAILS</Button>
      </div>

    </article>

    <article class="product gmc-event spacing paddown-hey">
      <img src="/images/icon-web/icon_gmc.png" alt="icon-web" class="pimage3" />
      <h3 class="product__title gmctitle">Geomodelling Competition (GMC)</h3>

      <div className= 'btn-events'>
        <Button onClick={() => setmodal3show(true)} buttonStyle='btn--outline'>EVENT DETAILS</Button>
      </div>

    </article>

    <article class="product virtuex-event spacing paddown-hey1">
      <img src="/images/icon-web/icon_virtuex.png" alt="icon-web" class="pimage4" />
      <h3 class="product__title">VIRTUAL GEOLOGICAL EXCURSION</h3>

      <div className= 'btn-events '>
      <Button onClick={() => setmodal4show(true)} buttonStyle='btn--outline'>EVENT DETAILS</Button>

      </div>

    </article>

    <article class="product webinar-event spacing paddown-hey">
      <img src="/images/icon-web/icon_webinar.png" alt="icon-web" class="pimage5" />
      <h3 class="product__title">WEBINAR</h3>

      <div className= 'btn-events'>
        <Button onClick={() => setmodal5show(true)} buttonStyle='btn--outline'>EVENT DETAILS</Button>
      </div>
      
    </article>

    <article class="product webinar-event spacing paddown-hey">
      <img src="/images/icon-web/icon_webinar.png" alt="icon-web" class="pimage5" />
      <h3 class="product__title">WEBINAR Series</h3>

      <div className= 'btn-events'>
        <Button onClick={() => setmodal6show(true)} buttonStyle='btn--outline'>EVENT DETAILS</Button>
      </div>
      
    </article>

  </div>


      <Modal
        size="lg"
        show={modal1show}
        onHide={() => setmodal1show(false)}
        aria-labelledby="modal1"
      >
        <Modal.Header closeButton>
          <Modal.Title id="modal1">
            GEOCLIP
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
<div className='image-wrapper'>
  <img className='modal-image' alt='banner-event' src='/images/poster/banner-geoclip.jpg'/>
</div>
<div class="modal__wrapper">
    <div class="modal__container">
        <p>GEOCLIP (Geology clip and essay contest) is a sub-event from GRANITE which has the theme “A Clip Means Million World” and also has 9 sub-themes in it, this GEOCLIP event has the aim of introducing geology to ordinary people and also has the aim to can be a means to express someone’s creativity through making videos or taking photos and also explain the meaning in it. The concept of this event is implemented in a manner participants submit videos/photos, minimum duration 30 seconds with maximum free duration with applications that have been defined, namely application Instagram and Upload it using hashtag #Granite2020 #GeoclipGranite and for photo contest simply send the work on email which is determined using the theme a clip means million words and submitting essays a maximum of 1000 useful words for explain the contest of the video/photo in more detail. Participants can send only videos or photos only or both. This competition is individual and each participants will get a participant certificate and for winners only get a certificate winner.</p>
        <h3>Sub Themes:</h3>
        <ul className='modal__list'>
            <li>Daily Life</li>
            <li>Entrepreneurs</li>
            <li>Infrastructure Development</li>
            <li>Sedimentology and Stratigraphy</li>
            <li>Vulcanology</li>
            <li>Geological Disaster</li>
            <li>Geoscience</li>
            <li>Mineralization</li>
            <li>Structure Geology</li>
          </ul>
        <h3>Grand Prize:</h3>
        <ul className='modal__list'>
            <li>1st Place: IDR 1.000.000</li>
            <li>2nd Place: IDR 750.000</li>
            <li>3rd Place: IDR 500.000</li>
        </ul>
        <h3>Fee Registration</h3>
        <ul className='modal__list'>
            <li>IDR 50.000 (College Students)</li>
            <li>IDR 20.000 (High School Students)</li>
        </ul>
        <p>*Free Entry for 20 first registrants (High School Students)</p>
        <h3>For further information (CP):</h3>
        <p className='marg-zero'>Risma Amalia</p>
        <ul className='modal__list'>
            <li>WA: 0813-9881-1072</li>
            <li>LINE: raylaaa</li>
        </ul>

    </div>
</div>


        </Modal.Body>
      </Modal>

      <Modal
        size="lg"
        show={modal2show}
        onHide={() => setmodal2show(false)}
        aria-labelledby="modal2"
      >
        <Modal.Header closeButton>
          <Modal.Title id="modal2">
            Geographic Information System (GIS)
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
        <div className='image-wrapper'>
  <img alt='banner-event' className='modal-image' src='/images/poster/banner-gis.jpg'/>
</div>
        <div class="modal__wrapper">
        <div class="modal__container">
            <p>The concept of the competition is a competition using the online or remote mapping method (GIS), where it is hoped that student can develop and use their knowledge of how to use geographic information systems to create an outcome that can determine the hazards of an area and make appropriate mitigation. Later it will be poured into a map or other work online. Each work produced will be assessed by a team of judges, where the jury will consist of lecturers who come from professionals and from the Trisakti University Geological Engineering Study Program. The concept of the competition is a competition by synchronizing the data that will be obtained using either drone, google earth, arcgis, global mapper or other GIS data. Where the synchronization is expected to be useful in hazard and mitigation. This race will be held for 7 days. Where the 1st day will be a workshop, 2-5th day of processing the data. And the 6th day is the day for making the report presentation file which each team will present before the jury, and the 7th day is time for presentation. All of these competition activities will be held online according to the existing conditions of the Indonesian state.</p>
            <h3>Grand Prize:</h3>
            <ul className="modal__list">
                <li>1st Place: IDR 4.000.000</li>
                <li>2nd Place: IDR 2.500.000</li>
                <li>3rd Place: IDR 1.500.000</li>
            </ul>
            <h3>Fee Registration</h3>
            <p>IDR 500.000/Team</p>
            <h3>For further information (CP):</h3>
            <p className="modal__list">Farhan Ryandi</p>
            <ul className="modal__list">
                <li>WA: 0822-1214-804559</li>
                <li>LINE: farhanryandi303</li>
            </ul>

        </div>
    </div>


        </Modal.Body>
      </Modal>

      <Modal
        size="lg"
        show={modal3show}
        onHide={() => setmodal3show(false)}
        aria-labelledby="modal3"
      >
        <Modal.Header closeButton>
          <Modal.Title id="modal3">
              Geomodelling Competition (GMC)
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
        <div className='image-wrapper'>
          <img alt='banner-event' className='modal-image' src='/images/poster/banner-gmc.jpg'/>
        </div>
        <div class="modal__wrapper">
        <div class="modal__container">
          <h3 style={ {text_align : "center"}}>EVENT CONCEPT</h3>
            <p>The concept of the Geological Modeling Competition is a competition in creating, analyzing, and interpreting subsurface geological modeling. This competition is devoted to both national and international students where the assessment will be carried out by geologists with a background of practitioner, academic, and researcher. The results of data processing will be made a report and presentation file which will be presented at the time and place that has been given.</p>
            <br></br>
            <h4 style={ {text_align : "center"}}> EVENT THEME : “Petrophysical Analysis and Volumetric Measure” </h4>
            <br></br>
            <p>
            Geological Modeling Competition is a competition that aims to analyze the physical properties of rock including the type of fluid contained and calculate the initial hydrocarbon reserves in a field using Microsoft Excel software.
            </p>

            <h3>Schedule : </h3>
            <div className='image-wrapper'>
          <img alt='banner-event' className='modal-image' src='/images/table_schedule.png'/>
        </div>
            <h3>Registration Fee</h3>
            <p>IDR 500.000/Team</p>
            <h3>Prize:</h3>
            <ul className='modal__list'>
                <li>1st Place: IDR 4.000.000</li>
                <li>2nd Place: IDR 2.500.000</li>
                <li>3rd Place: IDR 1.500.000</li>
            </ul>

            <h3>For further information (CP):</h3>
            <p className='marg-zero'>Geraldi Tarigan</p>
            <ul className='modal__list'>
                <li>WA: 0822-6986-4439</li>
                <li>LINE: geraldiatmarae</li>
            </ul>

        </div>
    </div>

        </Modal.Body>
      </Modal>

      <Modal
        size="lg"
        show={modal4show}
        onHide={() => setmodal4show(false)}
        aria-labelledby="modal4"
      >
        <Modal.Header closeButton>
          <Modal.Title id="modal4">
            Virtual Geological Excursion
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
        <div className='image-wrapper'>
  <img className='modal-image' src='/images/poster/banner-virtuex.jpg' alt='banner-event'/>
</div>
        <div class="modal__wrapper">
        <div class="modal__container">
            <p>Virtual excursion is an event in the form of an excursion to an area that aims for recreation as well as gaining new knowledge and experiences outside the classroom online or online. Where in a pandemic condition like this, with the condition that it does not allow everyone to do recreation or vacation directly, therefore this event is held to be able to overcome these problems as well as increase knowledge.</p>
            <h3>Fee Registration</h3>
            <p>IDR 30.000/Person</p>
            <h3>For further information (CP):</h3>
            <p className='marg-zero'>Siti Juhaima</p>
            <ul className='modal__list'>
                <li>WA: 0857-6285-3047</li>
            </ul>

        </div>
    </div>
        </Modal.Body>
      </Modal>

      <Modal
        size="lg"
        show={modal5show}
        onHide={() => setmodal5show(false)}
        aria-labelledby="modal5"
      >
        <Modal.Header closeButton>
          <Modal.Title id="modal5">
            Webinar
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
        <div class="modal__wrapper">
        <div class="modal__container">
        <div className='image-wrapper'>
  <img className='modal-image' alt='banner-event' src='/images/poster/banner-webinar.jpg'/>
  </div>
            <p>The event will be carried out online via zoom, where as the event usually begins with an opening by the MC, there is an opening ceremony or a welcome event in the form of an opening video prepared then followed by a speech. The main event of this webinar is a seminar session, namely a panel discussion with the topic “Uncovering Urban Geology in The New Indonesian Capital City”. Then it will be closed with the announcement of the winners of the granite competition.</p>
            <h3>For further information (CP):</h3>
            <p className='marg-zero'>Muhammad Ery C: 085781448163</p>
            <p className='marg-zero'>Deva Anata: 0857862505772</p>
        </div>
    </div>


        </Modal.Body>
      </Modal>

      <Modal
        size="lg"
        show={modal6show}
        onHide={() => setmodal6show(false)}
        aria-labelledby="modal6"
      >
        <Modal.Header closeButton>
          <Modal.Title id="modal6">
            Webinar Series
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
        <div class="modal__wrapper">
        <div class="modal__container">
        <div className='image-wrapper'>
  <img className='modal-image' alt='banner-event' src='/images/poster/banner-webinarseries.jpg'/>
</div>
            <p>The event will be carried out online via zoom, whereas the event will begin with an opening by the MC, there is an opening ceremony or a welcoming show in the form of an opening video prepared , followed by opening speeches. The main event of this webinar is a seminar session, namely a panel discussion with the topic "Carbon Capture and Storage implementation in Indonesia". Then it will be closed with the announcement of the winners of the granite competitions</p>
            <h4>Topic</h4>
            <p>"Carbon Capture and Storage implementation in Indonesia”.</p>
            <p>This seminar will discuss about CCS, which includes general explanations, history, concepts, and parameters used in reservoir determination, in this case the discussion is emphasized to discuss why the implementation of CCS in Indonesia is a necessity from a geological and a chemist's point of view. In this implementation, of course there are several things that must be elaborated and analyse beforehand, where the geological factors determine the injection, as well as other parameters.</p>
            <h5>Keynote Speaker:</h5>
            <ul className='modal__list'>
                <li>Prof. Dr. Ir. Djoko Santoso M.Sc (ITB)</li>
            </ul>
            <h5>Speaker:</h5>
            <ul className='modal__list'>
                <li>Dr., Ir., Eko Widianto, M.T. ( Trisakti)</li>
                <li>Prof. Dr Djoko Hartanto M.Si (ITS)</li>
            </ul>
            <h5>Moderator:</h5>
            <ul className='modal__list'>
                <li>Dwandari Ralanko (IAGI)</li>
            </ul>
            <h3>For further information (CP):</h3>
            <p className='marg-zero'>Muhammad Ery C: 085781448163</p>
            <p className='marg-zero'>Deva Anata: 0857862505772</p>
        </div>
    </div>


        </Modal.Body>
      </Modal>


</section>


</>
  );
}

export default EventsList;
