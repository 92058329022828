import React from 'react';
import '../../App.css';
import './AboutIntro.css';



function AboutIntro() {
  return (

    <div className='aboutus-box'>
      <div >
        <img className='aboutus-img' src="/images/aboutbox.jpg" alt='box_about'/>

      </div>
      <div className='text-box'>
        <h1 id='aboutustitle'>GRANITE</h1>
        <h2 id='aboutustext'>GRANITE 2020 is a series of activities carried out by the Trisakti University Geological Engineering Study Program and the Trisakti Geological Engineering Student Association. These activities are carried out virtually including Geology Webinars, Geological Scientific Competitions, and Geological Virtual Excursions that collaborate with relevant Government Agencies, Geologists, Energy and Mineral Resources Companies, as well as Students and the general public to provide insight and attention against the declining condition of the Energy Industry, as well as seeking solutions to overcome this. 2020 is the sixth GRANITE implementation. GRANITE 2020 has a concept of events and competitions with a theme of  "Breakthrough Geoscience in the Modern Digital World" to fill and implement the Sustainable Development Goals program to the 4.0 technological revolution in developing sustainable Energy and Mineral Resources and Human Resources to protect and maximize energy use , economic growth, employment, technological development, fair, comprehensive and effective energy access, and sustainable social development and environmental protection.
        </h2>
      </div>
    </div>
  );
}


export default AboutIntro;