import React from 'react';
import './Footer.css';
import { Link } from 'react-router-dom';

function Footer() {
  return (
    <>

      <section className='footer-sponsor  sponsor-bg'>
        <p className='footer-sponsor-heading'>
          SPONSORED BY : 
        </p>
        <div className='sponsor-wrapper'>
          <div className='sponsor-item'>
            <img id='sponsor-supported-logo' src='/images/sponsor-logo/sponsor.png' alt='support-logo'/>
          </div>
        </div>
      </section>

      <section className='footer-sponsor supported-bg'>
        <p className='footer-sponsor-heading'>
          SUPPORTED BY : 
        </p>
        <div className='sponsor-wrapper upper-sponsor'>
          <div className='sponsor-item'>
           <img id='sponsor-supported-logo' src='/images/support-logo/supported.png' alt='support-logo'/>
          </div>
        </div>
      </section>



      <section className='footer-sponsor partner-bg'>
        <p className='footer-sponsor-heading'>
          MEDIA PARTNER : 
        </p>
        <div className='sponsor-wrapper upper-sponsor'>
          <div className='sponsor-item'>
           <img id='sponsor-supported-logo' src='/images/medpar-logo/medpar.png' alt='support-logo'/>
          </div>
        </div>
      </section>



    <div className='footer-container'>
      <div class='footer-links'>
        <div className='footer-link-wrapper'>
          <div class='footer-link-items'>
            <h2>Department of Geological Engineering</h2>
            <h2>Faculty of Earth Technology and Energy</h2>
            <h2>Trisakti University</h2>
            <div class='footer-item'>


              <h3 class="text">
                <i className="fas fa-map-marker-alt" /> 

            <a
              className='gmaps-link'
              href='https://goo.gl/maps/wKXfmhYV6b5jK1Du8'
              target='_blank'
              aria-label='Granite-Location'
              rel="noopener noreferrer"
            >
                Address: Jl. Kyai Tapa No.1, RT.6/RW.16, Grogol, Jakarta 11440
            </a>  
              </h3>
            </div>
          </div>
          <div class='footer-link-items'>
  
            <div class='footer-item'>            
              <h3 class="text"><span role="img" aria-labelledby='emote1'>🛑 </span> For more information, please reach us through :</h3>
            </div>

            <div class='footer-item'>
              <h3 class="text">

              <a
                className='socmed-link'
                href='https://www.instagram.com/granite_trisakti/'
                target='_blank'
                aria-label='granite-insta'
                rel="noopener noreferrer"
              >
              <i class='fab fa-instagram' /> Instagram : @granite_trisakti
            </a>  
            </h3>

            </div>
            <div class='footer-item'>
              <h3 class="text">
              <a
                className='socmed-link'
                href='https://www.youtube.com/c/GraniteTrisakti'
                target='_blank'
                aria-label='granite-youtube'
                rel="noopener noreferrer"
              >
              <i class='fab fa-youtube' /> Youtube : Granite Trisakti
            </a>  
             </h3>

            </div>
            <div class='footer-item'>
              <h3 class="text">
              <a
                className='socmed-link'
                href='https://www.linkedin.com/in/granite-trisakti-ab97441b8/'
                target='_blank'
                aria-label='granite-linkedin'
                rel="noopener noreferrer"
              >
              <i class='fab fa-linkedin' /> Linkedin : Granite Trisakti
            </a>  

              </h3>
            </div>
            <div class='footer-item'>
              <h3 class="text">

              <a
                className='socmed-link'
                href='https://www.facebook.com/granite.geology.5'
                target='_blank'
                aria-label='granite-facebook'
                rel="noopener noreferrer"
              >
              <i class='fab fa-facebook' /> Facebook : Granite Universitas Trisakti
            </a>  
              </h3>
            </div>



            <div class='footer-item'>

              <h3 class="text">
              <i className="fas fa-envelope"/> Email :
                   granite.trisakti@gmail.com,      <br/>         
                   publicrelations.graniteusakti@gmail.com   </h3>
            </div>
              
          </div>
        </div>
      </div>
      <section class='social-media'>
        <div class='social-media-wrap'>
          <div class='footer-logo'>
            <Link to='/' className='social-logo'>
              GRNT
              {/* <i class='fab fa-typo3' /> */}
              <img alt='weblogo' src='/weblogo.png' className='navbar-logo-img'/>
              <img className='navbar-logo-img' src='/images/support-logo/hmtg-logo.png' alt='support-logo'/>

              <img className='navbar-logo-img' src='/images/support-logo/trisakti-logo.png' alt='support-logo'/>

            </Link>
          </div>
          <small class='website-rights'>GRNT © 2020</small>
          <div class='social-icons'>
            <a
              class='social-icon-link facebook'
              href='https://www.facebook.com/granite.geology.5'
              target='_blank'
              aria-label='Facebook'
              rel="noopener noreferrer"
            >
              <i class='fab fa-facebook-f' />
            </a>
            <a
              class='social-icon-link instagram'
              href='https://www.instagram.com/granite_trisakti/'
              target='_blank'
              aria-label='Instagram'
              rel="noopener noreferrer"
            >
              <i class='fab fa-instagram' />
            </a>
            <a
              class='social-icon-link youtube'
              href='https://www.youtube.com/c/GraniteTrisakti'
              target='_blank'
              aria-label='Youtube'
              rel="noopener noreferrer"
            >
              <i class='fab fa-youtube' />
            </a>
            <a
              class='social-icon-link linkedin'
              href='https://www.linkedin.com/in/granite-trisakti-ab97441b8/'
              target='_blank'
              aria-label='Linkedin'
              rel="noopener noreferrer"
            >
              <i class='fab fa-linkedin' />
            </a>
          </div>
        </div>
      </section>
    </div>
  </>
  );
}

export default Footer;
