import React from 'react';
import '../../App.css';
import BookList from '../guide_elm/BookList' ;
import BookHead from '../guide_elm/BookHead';
import Footer from '../Footer';

function Guidebook() {
  return (
    <>
      <BookHead />
      <BookList />
      <Footer />
    </>
  );
}

export default Guidebook;
