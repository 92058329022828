import React from 'react';
import {browserHistory } from 'react-dom';
import { BrowserRouter as Router, Switch, Route} from 'react-router-dom';
import './App.css';
import { Helmet } from 'react-helmet'


import Navbar from './components/Navbar';

// Pages
import Home from './components/pages/Home';
import Events from './components/pages/Events';
import AboutUs from './components/pages/AboutUs';
import SignUp from './components/pages/SignUp';
import Guidebook from './components/pages/Guidebook';


const TITLE = 'Granite 2020'

function App() {
    return (
    <>
        <Helmet>
          <title>{ TITLE }</title>
        </Helmet>
      <Router history={browserHistory}>
        <Navbar />
        <Switch>
          <Route path='/' exact component={Home} />
          <Route path='/' exact component={Home} />
          <Route path='/events' component={Events} />
          <Route path='/about-us' component={AboutUs} />
          <Route path='/sign-up' component={SignUp} />
          <Route path='/guidebook' component={Guidebook} />

          <Route path='/booklet-granite-2020' component={() => { 
             window.location.href = '/booklets/booklet.pdf'; 
             return null;
          }}/>      


{/* BOOKLETS */}
<Route path='/booklet/geoclip' component={() => { 
             window.location.href = '/booklets/booklet-geoclip.pdf'; 
             return null;
          }}/>      

        <Route path='/booklet/gis' component={() => { 
             window.location.href = '/booklets/booklet-gis.pdf'; 
             return null;
          }}/>      
        <Route path='/booklet/gmc' component={() => { 
             window.location.href = '/booklets/booklet-gmc.pdf'; 
             return null;
          }}/>      
        <Route path='/booklet/webinar' component={() => { 
             window.location.href = '/booklets/booklet-seminar.pdf'; 
             return null;
          }}/>      
          

          <Route path='/booklet/virtualex' component={() => { 
             window.location.href = '/booklets/booklet-virtual.pdf'; 
             return null;
          }}/>      



{/* forms */}
          <Route path='/forms/geoclip' component={() => { 
             window.location.href = 'http://bit.ly/GeoclipGranite2020'; 
             return null;
          }}/>
          <Route path='/forms/gis' component={() => { 
             window.location.href = 'http://bit.ly/GISVIRTUAL2020'; 
             return null;
          }}/>
          <Route path='/forms/gmc' component={() => { 
             window.location.href = 'http://bit.ly/GMCGranite2020'; 
             return null;
          }}/>
          <Route path='/forms/virtualgeo' component={() => { 
             window.location.href = 'http://bit.ly/VirtualExcursionGranite2020'; 
             return null;
          }}/>

          <Route path='/forms/webinar' component={() => { 
             window.location.href = 'http://bit.ly/WebinarGranite2020'; 
             return null;
          }}/>
            <Route path='/forms/webinar-series' component={() => { 
             window.location.href = 'http://bit.ly/WebinarSeriesGranite2020'; 
             return null;
          }}/>
        </Switch>
      </Router>
      
      
      <Router>
        {/* SOCIALS */}
      <Route path='/granite-facebook' component={() => { 
             window.location.href = 'https://www.facebook.com/granite.geology.5'; 
             return null;
          }}/>
          <Route path='/granite-instagram' component={() => { 
             window.location.href = 'https://www.instagram.com/granite_trisakti/'; 
             return null;
          }}/> 
          <Route path='/granite-youtube' component={() => { 
             window.location.href = 'https://www.youtube.com/c/GraniteTrisakti'; 
             return null;
          }}/>  
          <Route path='/granite-linkedin' component={() => { 
             window.location.href = 'https://www.linkedin.com/in/granite-trisakti-ab97441b8/'; 
             return null;
          }}/>        
      </Router>


    </>
  );
}

export default App;
