import React from 'react';
import '../../App.css';
// import Cards from '../Cards';
import HeroSection from '../HeroSection';
import IntroSection from '../IntroSection';
import Footer from '../Footer';
import Carousel from '../Carousel';
function Home() {
  return (
    <>
      <HeroSection />
      <IntroSection />
      <Carousel/> 
      {/* <Cards /> */}
      <Footer />
    </>
  );
}

export default Home;
