import React from 'react';
import '../../App.css';
import './EventsHead.css';

function EventsHead() {
  return (
    <div className='events-hero-container'>
      <h1>EVENTS</h1>
      <p>about granite 2020 event.</p>
    </div>
  );
}

export default EventsHead;
