import React from 'react';
import '../../App.css';
import EventsList from '../events_elm/EventsList' ;
import EventsHead from '../events_elm/EventsHead';
import Footer from '../Footer';

function Events() {
  return (
    <>
      <EventsHead />
      <EventsList />
      <Footer />
    </>
  );
}

export default Events;

