import React from 'react';
import '../../App.css';
import './AboutHistory.css';




function AboutHistory() {
  return (

    <div className='aboutus-box history-bg'>
      <div className='text-box history-box'>
        <h1 id='historytitle'>HISTORY</h1>
        <h2 id='historytext'>GRANITE is an annual scientific event that has existed since 2012, and continues in 2013, 2014, 2016, 2018, and now in 2020. And this event has also been attended by many Indonesian and international students, alumni, experts, practitioners , academics and bureaucrats. This year's Granite has a series of activities carried out in a virtual concept which includes a Geology Webinar, a Geological Science Competition, and a Geological Virtual Excursion.
        </h2>
      </div>
      <div >
        <img className='history-img' src="/images/history.jpg" alt='box_about'/>
      </div>
    </div>
  );
}


export default AboutHistory;