import React from 'react';
import '../../App.css';


import SignUpCards from '../signup_elm/SignUpCards';
import SignUpHead from '../signup_elm/SignUpHead';
import Footer from '../Footer';


export default function SignUp() {


  return (
  
  <>
  
  <SignUpHead />
  <SignUpCards />
  <Footer />
</>
);
}
