import React from 'react';
import '../App.css';
import './IntroSection.css';
import ReactPlayer from 'react-player';
import Flickity from "react-flickity-component";
import '../flickity.css';

const flickityOptions = {
  initialIndex: 1,
  fullscreen: true
}




function IntroSection() {
  return (

    <div className='intro-box'>
      <h1>GRANITE TRAILER</h1>
      
      <div className='intro-wrapper'>

      <Flickity      
    className={'carousel'} // default ''
    elementType={'div'} // default 'div'
    options={flickityOptions} // takes flickity options {}
    disableImagesLoaded={false} // default false
    reloadOnUpdate // default false
    static //
     >
         <div className='carousel-cell-vid'>
           <ReactPlayer
           className='caro-vid-item'
              url="https://youtu.be/7xLzQ1Y2XDA"
              controls
              playbackRate = {2}
              width = "1215px"
              height = "504px"
            />
         
         </div>
         <div className='carousel-cell-vid'>
         <ReactPlayer className='caro-vid-item'
              url="https://youtu.be/7xLzQ1Y2XDA"
              controls
              playbackRate = {2}
              width = "1215px"
              height = "504px"
            />
             
         </div>

         <div className='carousel-cell-vid'>
         <ReactPlayer
         className='caro-vid-item'
              url="https://youtu.be/7xLzQ1Y2XDA"
              controls
              playbackRate = {2}
              width = "1215px"
              height = "504px"
            />
             
         </div>

      </Flickity>


      </div>
    </div>
  );
}


export default IntroSection;