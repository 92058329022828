
import React from 'react';
import './Button.css';
import { Link } from 'react-router-dom';

const STYLES = ['btn--primary', 'btn--outline', 'btn--test'];

const SIZES = ['btn--medium', 'btn--large'];

const LINKNAME = ['/sign-up', '/about-us'];


export const Button = ({
  children,
  type,
  onClick,
  buttonStyle,
  buttonSize,
  linkName
}) => {
  const checkButtonStyle = STYLES.includes(buttonStyle)
    ? buttonStyle
    : STYLES[0];

  const checkButtonSize = SIZES.includes(buttonSize) ? buttonSize : SIZES[0];

  const checkLinkName = LINKNAME.includes(linkName) ? linkName : LINKNAME[0];



  return (
    <Link to= {checkLinkName}  className='btn-mobile'>
      <button
        className={`btn-style ${checkButtonStyle} ${checkButtonSize}`}
        onClick={onClick}
        type={type}
      >
        {children}
      </button>
    </Link>
  );
};
