import React from 'react';
import '../../App.css';
import './BookHead.css';

function BookHead() {
  return (
    <div className='guide-hero-container'>
      <h1>GUIDEBOOK</h1>
      <p>guidebooks for granite 2020 .</p>
    </div>
  );
}

export default BookHead;
