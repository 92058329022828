import React from 'react';
import '../../App.css';
import './AboutHead.css';

function AboutHead() {
  return (
    <div className='about-hero-container'>
      <h1>ABOUT US</h1>
      <p id='sub-header-about'>GRANITE</p>
      <p id='sub-about'>Geological Excursion, Seminar and Implemented Competitions.</p>
    </div>
  );
}

export default AboutHead;
