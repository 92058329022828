import React from 'react';
import './SignUpCards.css';
import CardItem from '../CardItem';

function SignUpCards() {
  return (
    <div className='cards'>
      <h1 id='signupcards_title'>FORMS :</h1>
      <div className='cards__container'>
        <div className='cards__wrapper'>
        <ul className='cards__items'>
            <CardItem
              src='/images/icon-web/icon_webinar.png'
              text='Webinar Series'
              label='WebSeries'
              path='/forms/webinar-series'
            />

            <CardItem
              src='/images/icon-web/icon_webinar.png'
              text='Webinar Closing'
              label='WebClosing'
              path='/forms/webinar'
            />

            <CardItem
              src='/images/icon-web/icon_virtuex.png'
              text='Virtual Excursion'
              label='VirtualEx'
              path='/forms/virtualgeo'
            />

          </ul>
          <ul className='cards__items'>
          <CardItem
              src='/images/icon-web/icon_gis.png'
              text='Geographic Information System'
              label='GIS'
              path='/forms/gis'
            />
            <CardItem
              src='/images/icon-web/icon_gmc.png'
              text='Geomodelling Competition'
              label='GMC'
              path='/forms/gmc'
            />
            <CardItem
              src='/images/icon-web/icon_geoclip.png'
              text='Geology Clip and Essay Contest'
              label='Geoclip'
              path='/forms/geoclip'
            />
            
          </ul>
        </div>
      </div>
    </div>
  );
}

export default SignUpCards;
