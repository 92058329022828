
import React, {useState} from 'react';

import Flickity from "react-flickity-component";
import '../flickity.css';
import './Carousel.css';    
import Modal from 'react-bootstrap/Modal';

const flickityOptions = {
    initialIndex: 4,
    fullscreen: true
}




function Carousel() {
    
    const [caro1_show, set_caro1_show] = useState(false);
    const [caro2_show, set_caro2_show] = useState(false);
    const [caro3_show, set_caro3_show] = useState(false);
    const [caro4_show, set_caro4_show] = useState(false);
    const [caro5_show, set_caro5_show] = useState(false);
    const [caro6_show, set_caro6_show] = useState(false);
    const [caro7_show, set_caro7_show] = useState(false);
    const [caro8_show, set_caro8_show] = useState(false);
    const [caro9_show, set_caro9_show] = useState(false);
return (


<div className='main_caro_container'>  
    <div className='carotitle__container'>
        <h1>EVENTS</h1>
    </div>
    <div className='carousel__container' >
   
    <Flickity      
    className={'carousel'} // default ''
    elementType={'div'} // default 'div'
    options={flickityOptions} // takes flickity options {}
    disableImagesLoaded={false} // default false
    reloadOnUpdate // default false
    static //
     >
         <div className='carousel-cell'>
         <button class='fullscreen-btn' href='#foo'  onClick={() => set_caro1_show(true)}>
         <i class='fas fa-expand fa-lg' /></button>
            <img className='caro_item carousel-cell-image' alt='caro_item' src="/images/poster/poster-geoclip.jpg" />
         
         </div>
         <div className='carousel-cell carousel-cell-image'>
         <button class='fullscreen-btn' href='#foo'  onClick={() => set_caro2_show(true)}>
         <i class='fas fa-expand fa-lg' /></button>
         <img className='caro_item carousel-cell-image' alt='caro_item' src="/images/poster/poster-gis.jpg" />
             
         </div>

         <div className='carousel-cell'>
         <button class='fullscreen-btn' href='#foo'  onClick={() => set_caro3_show(true)}>
         <i class='fas fa-expand fa-lg' /></button>
         <img className='caro_item carousel-cell-image' alt='caro_item' src="/images/poster/poster-virtuex.jpg" />             
         </div>

         <div className='carousel-cell'>
         <button class='fullscreen-btn' href='#foo'  onClick={() => set_caro7_show(true)}>
         <i class='fas fa-expand fa-lg' /></button>
         <img className='caro_item carousel-cell-image' alt='caro_item' src="/images/poster/poster-gmc.jpg" />             
         </div>

         <div className='carousel-cell'>
         <button class='fullscreen-btn' href='#foo'  onClick={() => set_caro9_show(true)}>
         <i class='fas fa-expand fa-lg' /></button>
         <img className='caro_item carousel-cell-image' alt='caro_item' src="/images/poster/poster-seluruh-acara.jpg" />             
         </div>


         <div className='carousel-cell'>
         <button class='fullscreen-btn' href='#foo'  onClick={() => set_caro8_show(true)}>
         <i class='fas fa-expand fa-lg' /></button>
         <img className='caro_item carousel-cell-image' alt='caro_item' src="/images/poster/poster-webinarutama.jpg" />             
         </div>
         
         <div className='carousel-cell'>
         <button class='fullscreen-btn' href='#foo'  onClick={() => set_caro4_show(true)}>
         <i class='fas fa-expand fa-lg' /></button>
         <img className='caro_item carousel-cell-image' alt='caro_item' src="/images/poster/poster-webinarseries1.jpg" />             
         </div>

         <div className='carousel-cell'>
         <button class='fullscreen-btn' href='#foo'  onClick={() => set_caro5_show(true)}>
         <i class='fas fa-expand fa-lg' /></button>
         <img className='caro_item carousel-cell-image' alt='caro_item' src="/images/poster/poster-webinarseries2.jpg" />             
         </div>

         <div className='carousel-cell'>
         <button class='fullscreen-btn' href='#foo'  onClick={() => set_caro6_show(true)}>
         <i class='fas fa-expand fa-lg' /></button>
         <img className='caro_item carousel-cell-image' alt='caro_item' src="/images/poster/poster-webinarseries3.jpg" />             
         </div>


      </Flickity>

      </div>


      <Modal
        size="xl"
        show={caro1_show}
        onHide={() => set_caro1_show(false)}
        aria-labelledby="modal1caro"
        dialogClassName="modal_fulscr"
      >
        <img className='modal_img_home' alt='caro_item' src="/images/poster/poster-geoclip.jpg" />


      </Modal>


      <Modal
        size="xl"
        show={caro2_show}
        onHide={() => set_caro2_show(false)}
        aria-labelledby="modal2caro"
        dialogClassName="modal_fulscr"
      >
         <img  className='modal_img_home'  alt='caro_item' src="/images/poster/poster-gis.jpg" />



      </Modal>




      <Modal
        size="xl"
        show={caro3_show}
        onHide={() => set_caro3_show(false)}
        aria-labelledby="modal3caro"
        dialogClassName="modal_fulscr"
      >
         <img  className='modal_img_home'  alt='caro_item' src="/images/poster/poster-virtuex.jpg" />             

      </Modal>

      <Modal
        size="xl"
        show={caro7_show}
        onHide={() => set_caro7_show(false)}
        aria-labelledby="modal3caro"
        dialogClassName="modal_fulscr"
      >
         <img  className='modal_img_home'  alt='caro_item' src="/images/poster/poster-gmc.jpg" />             

      </Modal>


      <Modal
        size="xl"
        show={caro4_show}
        onHide={() => set_caro4_show(false)}
        aria-labelledby="modal4caro"
        dialogClassName="modal_fulscr"
      >
         <img  className='modal_img_home'  alt='caro_item' src="/images/poster/poster-webinarseries1.jpg" />             

      </Modal>


      <Modal
        size="xl"
        show={caro5_show}
        onHide={() => set_caro5_show(false)}
        aria-labelledby="modal5caro"
        dialogClassName="modal_fulscr"
      >
         <img  className='modal_img_home'  alt='caro_item' src="/images/poster/poster-webinarseries2.jpg" />             

      </Modal>

      <Modal
        size="xl"
        show={caro6_show}
        onHide={() => set_caro6_show(false)}
        aria-labelledby="modal5caro"
        dialogClassName="modal_fulscr"
      >
         <img  className='modal_img_home'  alt='caro_item' src="/images/poster/poster-webinarseries3.jpg" />             

      </Modal>


      <Modal
        size="xl"
        show={caro8_show}
        onHide={() => set_caro8_show(false)}
        aria-labelledby="modal8caro"
        dialogClassName="modal_fulscr"
      >
         <img  className='modal_img_home'  alt='caro_item' src="/images/poster/poster-webinarutama.jpg" />             

      </Modal>

      <Modal
        size="xl"
        show={caro9_show}
        onHide={() => set_caro9_show(false)}
        aria-labelledby="modal8caro"
        dialogClassName="modal_fulscr"
      >
         <img  className='modal_img_home'  alt='caro_item' src="/images/poster/poster-seluruh-acara.jpg" />             

      </Modal>


</div>

);
}

export default Carousel;
