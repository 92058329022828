import React from 'react';
import '../../App.css';
import './SignUpHead.css';

function SignUpHead() {
  return (
    <div className='sign-hero-container'>
      <h1>REGISTRATION</h1>
      <p>registration for events in granite.</p>
    </div>
  );
}

export default SignUpHead;
