import React from 'react';
import '../../App.css';


import AboutHead from '../about_elm/AboutHead';
import AboutIntro from '../about_elm/AboutIntro';
import AboutHistory from '../about_elm/AboutHistory';
import Footer from '../Footer';



export default function AboutUs() {


  return (
  
  <>
    <AboutHead />
    <AboutIntro />
    <AboutHistory />
    <Footer />
  </>
);
}
