import React from 'react';
import '../../App.css';
import './BookList.css';
import { Link } from 'react-router-dom';


function BookList() {
  return (
      <>
<div className='download-container'>
    <div className='download-title'>
        <h1>DOWNLOADS</h1>
    </div>
    <div className='mid-section'>
    <div class="booklet-container">
        <div class="guide-book">
            <div class="guide-book-preview">
                <h2>PDF</h2>
            </div>
            <div class="guide-book-info">
                <h2>Granite 2020 Booklet</h2>
                <div class='gb-btn-container'>
                    <Link   target="_blank" to='/booklet-granite-2020' class="gb-btn">Download</Link>
                </div>
            </div>
        </div>
    </div>
    </div>
</div>
    </>
  );
}

export default BookList;
